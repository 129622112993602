<template>
  <data-table
    :headers="headers"
    :items="items"
    :last-page="serverLastPage"
    :loading="bLocalLoading"
    :options="pagination"
    :total="serverItemsLength"
    btn-action-item-key-id="id"
    btn-action-path="contracts"
    hide-btn-action-view
    is-route-name
    @delete="deleteItem"
    @update:sort-by="onSortBy"
    @update:sort-desc="onSortDesc"
    @update:options="onPagination"
    @update:page="onPage"
  >
    <template v-slot:[`item.active`]="{ item }">
      <active-icon :active="item.active" />
    </template>

    <template #[`item.name`]="{ item }">
      <v-btn
        :to="{ name: 'contracts.update', params: { id: item.id } }"
        color="primary"
        text
      >
        {{ item.name }}
      </v-btn>
    </template>

    <template #[`item.status`]="{ item }">
      {{ $t(`status.contract.${item.status}`) }}
    </template>

    <template #[`item.latest_invoice`]="{ item }">
      <contract-invoice-preview
        v-if="item.latest_invoice?.id"
        :value="item.latest_invoice"
      />
    </template>

    <template #[`item.customer`]="{ item }">
      <customer-preview :customer="item.customer" />
    </template>

    <template #[`item.actions`]="{ item }">
      <contract-list-actions
        :item="item"
        @createinvoices="onCreateContractInvoices"
        @delete="deleteItem"
      />
    </template>
  </data-table>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ContractsMixin from "@/modules/contracts/mixins/ContractsMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import CustomerPreview from "@/modules/customers/components/CustomerPreview.vue";
import { ContractData } from "@planetadeleste/vue-mc-gw";
import ContractInvoicePreview from "@/modules/contracts/components/ContractInvoicePreview.vue";
import ContractListActions from "@/modules/contracts/components/ContractListActions.vue";
import { Dictionary, map, set } from "lodash";

@Component({
  components: {
    ContractListActions,
    ContractInvoicePreview,
    CustomerPreview,
    ActiveIcon,
  },
})
export default class ContractsList extends Mixins(ContractsMixin) {
  isList = true;
  arLoading: number[] = [];

  get items() {
    return map<Partial<ContractData>[]>(
      this.obCollection.getModelList() as unknown as Dictionary<
        Partial<ContractData>[]
      >,
      (obItem: ContractData) => {
        if (this.arLoading.length && this.arLoading.includes(obItem.id)) {
          set(obItem, "css", "striped indigo lighten-5");
        }

        return obItem;
      }
    );
  }

  async onCreateContractInvoices(obData: ContractData) {
    this.bLocalLoading = true;
    const index = this.arLoading.push(obData.id);
    await this.onCreateInvoices(obData.id);
    await this.index();
    this.bLocalLoading = false;
    this.arLoading.splice(index - 1, 1);
  }

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "code", value: "code" },
      { text: "name", value: "name" },
      { text: "customer", value: "customer" },
      { text: "status", value: "status" },
      { text: "frequency", value: "frequency" },
      { text: "last.invoice.date", value: "latest_invoice" },
    ];
    this.addDTHeaders(arHeaders);

    this.index();
  }
}
</script>
